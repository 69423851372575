import React from 'react';
import PropTypes from 'prop-types';

const NewPostButton = (props) => (
  <div id='NewPostButton' className='demo-button demo-button-c launch-post-box icon-new-post-white' onClick={props.openNewPostModal} role='button' tabIndex={0}>
    New Post
  </div>
);

NewPostButton.propTypes = {
  openNewPostModal: PropTypes.func,
};

export default NewPostButton;