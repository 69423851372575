import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { NavLink } from 'react-router-dom';
import Icon from 'brighteon/components/icon';

class DirectoryTab extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className='connects-tab mb3'>
        <NavLink to='/timelines/home' activeClassName='active' >
          <div className='notification__nav-back'>
            <Icon id='arrow-left' size={30} />
          </div>
        </NavLink>
        <div className='connects-tab-text-container'>
          <span className='connects-tab-text'>Browse Profiles</span>
        </div>
      </div>
    );
  }

}

export default DirectoryTab;
