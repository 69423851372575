import React from 'react';
import api from 'brighteon/api';

class BrighteonShopContainer extends React.PureComponent {

  state = {
    products: [],
  }

  componentDidMount() {
    api().get('/api/v1/shopify/products')
      .then(({ data }) => {
        this.setState({ products: data.products });
      })
      .catch(err => console.warn(err));
  }

  render() {
    const products = this.state.products;

    return (
      <div className='widget widget-highlight brighteon-shop'>
        <span className='title'>
          <a href='https://www.brighteonstore.com' target='_blank'>Brighteon Shop</a>
        </span>
        <div className='content'>
          <div className='text'>
            <p>
              BrighteonStore.com features quality survival gear, lab-tested vitamins, supplements and more. Proceeds from every sale at BrighteonStore.com helps keep the Brighteon family of sites operating.
            </p>
          </div>
          <div className='products'>
            {
              products.map((product, i) => {
                return (
                  <div className='product' key={i}>
                    <a href={product.url} target='_blank'>
                      <div className='product-image'>
                        <img src={product.img_url} className='w-100' alt='product_image_url' />
                      </div>
                      <div className='product-title'>
                        <span>{product.title}</span>
                      </div>
                    </a>
                  </div>
                );
              })
            }
          </div>
          <div className='actions'>
            <a href='https://www.brighteonstore.com' target='_blank' className='demo-button demo-button-a demo-button--block'>Shop Now</a>
            <a href='https://www.brighteonstore.com' target='_blank'>BrighteonStore.com</a>
          </div>
        </div>
      </div>
    );
  }

}

export default BrighteonShopContainer;
