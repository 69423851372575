import React from 'react';

class DonateBoxContainer extends React.PureComponent {

  render() {
    return (
      <div className='widget'>
        <span className='title'>
          Brighteon Radio
        </span>
        <div className='content'>
          <div>
            <img className='w-100 radius-rounded-4px' src='https://www.brighteonradio.com/assets/images/Brighteon-Radio.jpg' alt='brighteon-radio' />
          </div>
          <div className='text'>
            <p>
              <strong>Free-speech internet radio</strong> featuring Wayne Cook, Sam Bushman, Jim White, Debbie Georgatos, Wayne Allyn, Gene Ho and many more! Streaming weekdays 8am-10:30pm EST.
            </p>
          </div>
          <div className='actions'>
            <a href='https://www.brighteonradio.com' target='_blank' className='demo-button demo-button-a demo-button--block'>Listen Now</a>
          </div>
        </div>
      </div>
    );
  }

}

export default DonateBoxContainer;
