import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import PropTypes from 'prop-types';
import Icon from '../../../components/icon';
import { profile_directory } from '../../../initial_state';
import NotificationsCounterIcon from './notifications_counter_icon';
import ChatUnreadCounterIcon from './chat_unread_counter_icon';
import FollowRequestsNavLink from '../../../features/ui/components/follow_requests_nav_link';
import NewPostButton from './new_post_button';

const iconStyle = {
  size: 24,
};

const navLinkClass = 'column-link column-link--transparent d-flex';

const MenuTextContainer = (props) => {
  return (
    <div className='ml2 pt1'>
      <FormattedMessage {...props} />
    </div>
  );
};

const NavMenuContainer = (props) => {
  return (
    <NavLink className={navLinkClass} to={props.to} data-preview-title-id={props.previewTitleId}>
      <Icon className='column-link__icon' id={props.iconId} size={iconStyle.size} />
      <MenuTextContainer id={props.messageId} defaultMessage={props.message} />
    </NavLink>
  );
};

NavMenuContainer.propTypes = {
  to: PropTypes.string,
  previewTitleId: PropTypes.string,
  iconId: PropTypes.string,
  messageId: PropTypes.string,
  message: PropTypes.string,
};

class NavigationPanel extends ImmutablePureComponent {

  render() {
    const { account } = this.props;
    return (
      <div className='navigation-panel-left'>
        <NavMenuContainer to='/timelines/home' previewTitleId='column.home' iconId='home' messageId='tabs_bar.home' message='Home' />
        <NavMenuContainer to='/timelines/public' previewTitleId='column.notifications' iconId='live-stream' messageId='tabs_bar.explore' message='Explore' />
        <NavMenuContainer to='/timelines/trend' previewTitleId='column.trend' iconId='trending' messageId='tabs_bar.trending' message='Trending' />

        <NavLink className={navLinkClass} to='/notifications' data-preview-title-id='column.notifications'>
          <NotificationsCounterIcon className='column-link__icon' size={iconStyle.size} />
          <MenuTextContainer id='tabs_bar.notifications' defaultMessage='Notifications' />
        </NavLink>

        <FollowRequestsNavLink nav />
        <NavLink className={navLinkClass} to='/messages'>
          <ChatUnreadCounterIcon className='column-link__icon' id='envelope' size={iconStyle.size} />
          <MenuTextContainer id='navigation_bar.direct' defaultMessage='Messages' />
        </NavLink>

        <NavMenuContainer to='/settings/relationships' iconId='users' messageId='navigation_bar.follows_and_followers' message='Follows and followers' />

        {
          profile_directory && <NavMenuContainer to='/directory' iconId='address-book-o' messageId='getting_started.directory' message='Connect' />
        }

        <NavMenuContainer to='/favourites' iconId='star' messageId='navigation_bar.favourites' message='Favorites' />
        <NavMenuContainer to='/bookmarks' iconId='bookmark' messageId='navigation_bar.bookmarks' message='Bookmarks' />
        <NavMenuContainer to='/brighteon_feed' iconId='brighteon-feed' messageId='navigation_bar.brighteon_feed' message='Brighteon Feed' />

        <a className={navLinkClass} href='https://announcements.brighteon.social' target='_blank'>
          <Icon className='column-link__icon' id='announcements' size={iconStyle.size} />
          <MenuTextContainer id='navigation_bar.announcements' defaultMessage='Announcements' />
        </a>

        <NavMenuContainer to='/lists' iconId='list-ul' messageId='navigation_bar.lists' message='Lists' />

        <a className={navLinkClass} href={account.get('url')} target='_blank'>
          <Icon className='column-link__icon' id='profile' size={iconStyle.size} />
          <MenuTextContainer id='navigation_bar.profile' defaultMessage='Profile' />
        </a>

        <a className={navLinkClass} href='/help' target='_blank'>
          <Icon className='column-link__icon' id='help' size={iconStyle.size} />
          <MenuTextContainer id='navigation_bar.help' defaultMessage='Help' />
        </a>

        <div className='px15px mt20px w-198px color-medium2'>
          For immediate assistance, use the hashtag
          &nbsp;<span className='color-dark'>#BrighteonSupport</span>&nbsp;
          and tag <a href='/@BrighteonAdminOfficial'>@BrighteonAdminOfficial</a>
        </div>

        <NewPostButton openNewPostModal={this.props.onShowCompose} />
      </div>
    );
  }

}

export default withRouter(NavigationPanel);
