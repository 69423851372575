import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import ImmutablePureComponent from 'react-immutable-pure-component';
import Text from '../../messages/other_components/text';
import StickMessageSearch from '../components/stick_message_search';
import StickMessageList from '../components/stick_message_list';
import Icon from '../../../../brighteon/components/icon';
import { openModal } from '../../../actions/modal';
import { MODAL_CHAT_CONVERSATION_CREATE } from '../../../constants';
import IconButton from '../../../../brighteon/components/icon_button';

class StickMessageListContainer extends ImmutablePureComponent {

  static propTypes = {
    onExpand: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
  }

  handleClick = () => {
    this.props.onExpand();
  }

  handleOnCreateNewChat = () => {
    this.props.onOpenChatConversationCreateModal();
  }

  render () {
    const { expanded } = this.props;
    const arrowId = expanded ? 'arrow-down' : 'arrow-up';
    return (
      <div className={classNames('border-medium1-1 bg-white w-270px')}>
        <div className={'border-bottom-medium1-1 px15px py10px d-flex justify-content-space-between align-items-center'}>
          <Text color='secondary' weight='medium' align='left'>
            Recent Chats
          </Text>

          <div className='d-flex'>
            <NavLink to='/messages/requests' className='circle icon-button p3px'>
              <Icon id='preferences' size={20} className='' title='Chat Preferences' />
            </NavLink>
            <IconButton icon='new-gray' size={20} className='circle ml10px' title='New Chat' onClick={this.handleOnCreateNewChat} />
            <Icon id={arrowId} size={24} onClick={this.handleClick} className='cursor-pointer ml10px' />
          </div>
        </div>
        {
          expanded && (
            <div className='h-100vh-124px'>
              <StickMessageSearch />
              <StickMessageList />
            </div>
          )
        }
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  onOpenChatConversationCreateModal() {
    dispatch(openModal(MODAL_CHAT_CONVERSATION_CREATE));
  },
});

export default connect(null, mapDispatchToProps)(StickMessageListContainer);
