import { connect } from 'react-redux';
import ColumnsArea from '../components/columns_area';
import { makeGetStatus } from '../../../selectors';
import { showComposePopup } from '../../../actions/compose';

const mapStateToProps = state => {
  const getStatus = makeGetStatus();

  return {
    showFilterBar: state.getIn(['settings', 'notifications', 'quickFilter', 'show']),
    columns: state.getIn(['settings', 'columns']),
    isModalOpen: !!state.get('modal').modalType,
    statusToReply: getStatus(state, { id: state.getIn(['compose', 'in_reply_to']) }),
  };
};

const mapDispatchToProps = dispatch => ({
  onShowCompose (showPopup) {
    dispatch(showComposePopup(showPopup));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ColumnsArea);
