import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import { isUserTouching } from '../../../is_mobile';
import { me } from '../../../initial_state';
import NotificationsCounterBadge from './notifications_counter_badge';
import ChatUnreadCounterBadge from './chat_unread_counter_badge';
import classNames from 'classnames';

let tlinks = [];
if (me) {
  tlinks = [
    (<NavLink className='tabs-bar__link white-space-no-wrap' to='/timelines/home' data-preview-title-id='column.home' data-preview-icon='home' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.home' defaultMessage='Home' /></div>
    </NavLink>),
    <NavLink className='tabs-bar__link white-space-no-wrap' to='/timelines/public' data-preview-title-id='column.explore' data-preview-icon='bell' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.explore' defaultMessage='Explore' className='pb5px' /></div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' to='/timelines/trend' data-preview-title-id='column.trend' data-preview-icon='users' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.trend' defaultMessage='Trend' className='pb5px' /></div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' exact to='/notifications' data-preview-title-id='column.notifications' data-preview-icon='globe' >
      <div className='pb5px pos-relative'>
        <FormattedMessage id='tabs_bar.notifications' defaultMessage='Notifications' className='pb5px' />
        <NotificationsCounterBadge />
      </div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' exact to='/follow_requests' data-preview-title-id='column.follow_requests' data-preview-icon='bar-chart' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.follow_requests' defaultMessage='Follow Requests' className='pb5px' /></div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' exact to='/messages' data-preview-title-id='column.messages' data-preview-icon='uncensored-news-white' >
      <div className='pb5px'>
        <FormattedMessage id='tabs_bar.messages' defaultMessage='Messages' className='pb5px' />
        <ChatUnreadCounterBadge />
      </div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' exact to='/settings/relationships' data-preview-title-id='column.relationships' data-preview-icon='hashtag' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.relationships' defaultMessage='Follows & Followers' className='pb5px' /></div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap optional' to='/directory' data-preview-title-id='column.connect' data-preview-icon='bell' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.connect' defaultMessage='Connect' className='pb5px' /></div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' to='/favourites' data-preview-title-id='column.favorites' data-preview-icon='bell' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.favorites' defaultMessage='Favorites' className='pb5px' /></div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' to='/bookmarks' data-preview-title-id='column.bookmarks' data-preview-icon='bell' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.bookmarks' defaultMessage='Bookmarks' className='pb5px' /></div>
    </NavLink>,
    <a className='tabs-bar__link white-space-no-wrap' href='/redirect_me' data-preview-title-id='column.profile' data-preview-icon='bell' key='profile' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.profile' defaultMessage='Profile' className='pb5px' /></div>
    </a>,
    <NavLink className='tabs-bar__link white-space-no-wrap' to='/lists' data-preview-title-id='column.lists' data-preview-icon='bell' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.lists' defaultMessage='Lists' className='pb5px' /></div>
    </NavLink>,
  ];
} else {
  tlinks = [
    <NavLink className='tabs-bar__link white-space-no-wrap' to='/timelines/trend' data-preview-title-id='column.trend' data-preview-icon='users' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.trend' defaultMessage='Trend' className='pb5px' /></div>
    </NavLink>,
    <NavLink className='tabs-bar__link white-space-no-wrap' to='/timelines/news' data-preview-title-id='column.news' data-preview-icon='bell' >
      <div className='pb5px'><FormattedMessage id='tabs_bar.news' defaultMessage='News' className='pb5px' /></div>
    </NavLink>,
  ];
}

export const links = tlinks;

export function getIndex (path) {
  return links.findIndex(link => link.props.to === path);
}

export function getLink (index) {
  return links[index].props.to;
}

export default @injectIntl
@withRouter
class TabsBar extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  setRef = ref => {
    this.node = ref;
  }

  handleClick = (e) => {
    // Only apply optimization for touch devices, which we assume are slower
    // We thus avoid the 250ms delay for non-touch devices and the lag for touch devices
    if (isUserTouching()) {
      e.persist();

      requestAnimationFrame(() => {
        const tabs = Array(...this.node.querySelectorAll('.tabs-bar__link'));
        const currentTab = tabs.find(tab => tab.classList.contains('active'));
        const nextTab = tabs.find(tab => tab.contains(e.target));
        const { props: { to } } = links[Array(...this.node.childNodes).indexOf(nextTab)];


        if (currentTab !== nextTab) {
          if (currentTab) {
            currentTab.classList.remove('active');
          }

          const listener = debounce(() => {
            nextTab.removeEventListener('transitionend', listener);
            this.props.history.push(to);
          }, 50);

          nextTab.addEventListener('transitionend', listener);
          nextTab.classList.add('active');
        }
      });
    }

  }

  render () {
    const { intl: { formatMessage }, className } = this.props;

    return (
      <div className={classNames('tabs-bar__wrapper mb10px', className)}>
        <nav className='tabs-bar hide-scrollbar' ref={this.setRef}>
          {links.map(link => React.cloneElement(link, { key: link.props.to, onClick: this.handleClick, 'aria-label': formatMessage({ id: link.props['data-preview-title-id'] }) }))}
        </nav>

        <div id='tabs-bar__portal' className='rounded-top overflow-hidden' />
      </div>
    );
  }

}
