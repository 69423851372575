import { connect } from 'react-redux';
import Badge from 'brighteon/components/badge';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getNotifications = createSelector([
  state => ImmutableList(state.getIn(['settings', 'notifications', 'shows']).filter(item => !item).keys()),
  state => state.getIn(['notifications_status_bar', 'items']),
], (excludedTypes, notifications) => {
  return notifications.filter(item => item !== null)
    .filter(item => item.get('read') !== true)
    .filter(item => !excludedTypes.includes(item.get('type')));
});

const mapStateToProps = state => ({
  count: getNotifications(state).size,
  id: 'bell',
});

export default connect(mapStateToProps)(Badge);
