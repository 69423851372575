import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ImmutablePureComponent from 'react-immutable-pure-component';

import ReactSwipeableViews from 'react-swipeable-views';
import TabsBar, { links, getIndex, getLink } from './tabs_bar';
import NotificationFilterBarContainer from '../../notifications/containers/filter_bar_container';

import { disableSwiping } from 'brighteon/initial_state';

import BundleContainer from '../containers/bundle_container';
import ColumnLoading from './column_loading';
import DrawerLoading from './drawer_loading';
import BundleColumnError from './bundle_column_error';
import {
  Compose,
  Notifications,
  HomeTimeline,
  CommunityTimeline,
  PublicTimeline,
  HashtagTimeline,
  DirectTimeline,
  FavouritedStatuses,
  BookmarkedStatuses,
  ListTimeline,
  Directory,
} from '../../ui/util/async-components';
import Icon from '../../../../brighteon/components/icon';
import ComposeFormContainer from '../../../../brighteon/containers/compose_form_container';
import ComposePanel from './compose_panel';
import NavigationPanel from '../../../../brighteon/features/ui/components/navigation_panel';
import UnsignedNavPanel from '../../../../brighteon/features/ui/components/unsigned_nav_panel';

import { supportsPassiveEvents } from 'detect-passive-events';
import { scrollRight } from '../../../scroll';
import NewsNavigation from './news_navigation';
import DirectoryTab from './directory_tab';
import classNames from 'classnames';
import { me } from '../../../initial_state';
import StickMessagePanel from '../../../features/stick_message_panel';

const componentMap = {
  'COMPOSE': Compose,
  'HOME': HomeTimeline,
  'NOTIFICATIONS': Notifications,
  'PUBLIC': PublicTimeline,
  'REMOTE': PublicTimeline,
  'COMMUNITY': CommunityTimeline,
  'HASHTAG': HashtagTimeline,
  'DIRECT': DirectTimeline,
  'FAVOURITES': FavouritedStatuses,
  'BOOKMARKS': BookmarkedStatuses,
  'LIST': ListTimeline,
  'DIRECTORY': Directory,
};

const shouldHideFAB = path => path.match(/^\/statuses\/|^\/search|^\/getting-started/);

export default @(component => injectIntl(component, { withRef: true }))
class ColumnsArea extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static propTypes = {
    intl: PropTypes.object.isRequired,
    columns: ImmutablePropTypes.list.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    singleColumn: PropTypes.bool,
    children: PropTypes.node,
    statusToReply: PropTypes.object,
    account: PropTypes.object,
    onShowCompose: PropTypes.func.isRequired,
    showFilterBar: PropTypes.bool,
  };

  state = {
    shouldAnimate: false,
  }

  UNSAFE_componentWillReceiveProps() {
    if (typeof this.pendingIndex !== 'number' && this.lastIndex !== getIndex(this.context.router.history.location.pathname)) {
      this.setState({ shouldAnimate: false });
    }
  }

  componentDidMount() {
    if (!this.props.singleColumn) {
      this.node.addEventListener('wheel', this.handleWheel, supportsPassiveEvents ? { passive: true } : false);
    }
    // window.addEventListener('scroll', this.handleScroll, { passive: true });

    this.lastIndex = getIndex(this.context.router.history.location.pathname);
    this.isRtlLayout = document.getElementsByTagName('body')[0].classList.contains('rtl');

    this.setState({ shouldAnimate: true });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.singleColumn !== nextProps.singleColumn && nextProps.singleColumn) {
      this.node.removeEventListener('wheel', this.handleWheel);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.singleColumn !== prevProps.singleColumn && !this.props.singleColumn) {
      this.node.addEventListener('wheel', this.handleWheel, supportsPassiveEvents ? { passive: true } : false);
    }

    const newIndex = getIndex(this.context.router.history.location.pathname);

    if (this.lastIndex !== newIndex) {
      this.lastIndex = newIndex;
      this.setState({ shouldAnimate: true });
    }
  }

  componentWillUnmount() {
    if (!this.props.singleColumn) {
      this.node.removeEventListener('wheel', this.handleWheel);
    }
  }

  handleChildrenContentChange() {
    if (!this.props.singleColumn) {
      const modifier = this.isRtlLayout ? -1 : 1;
      this._interruptScrollAnimation = scrollRight(this.node, (this.node.scrollWidth - window.innerWidth) * modifier);
    }
  }

  handleSwipe = (index) => {
    this.pendingIndex = index;

    const nextLinkTranslationId = links[index].props['data-preview-title-id'];
    const currentLinkSelector = '.tabs-bar__link.active';
    const nextLinkSelector = `.tabs-bar__link[data-preview-title-id="${nextLinkTranslationId}"]`;

    // HACK: Remove the active class from the current link and set it to the next one
    // React-router does this for us, but too late, feeling laggy.
    document.querySelector(currentLinkSelector).classList.remove('active');
    document.querySelector(nextLinkSelector).classList.add('active');

    if (!this.state.shouldAnimate && typeof this.pendingIndex === 'number') {
      this.context.router.history.push(getLink(this.pendingIndex));
      this.pendingIndex = null;
    }
  }

  handleAnimationEnd = () => {
    if (typeof this.pendingIndex === 'number') {
      this.context.router.history.push(getLink(this.pendingIndex));
      this.pendingIndex = null;
    }
  }

  handleWheel = () => {
    if (typeof this._interruptScrollAnimation !== 'function') {
      return;
    }

    this._interruptScrollAnimation();
  }

  handleScroll = () => {
    const rightPanel = document.getElementsByClassName('right-panel')[0];
    if (!rightPanel) {
      return;
    }

    const rightPanelHeight = rightPanel.clientHeight;
    const rightPanelTop = rightPanel.style.top ? parseInt(rightPanel.style.top) : 0;
    const pageYOffset = window.pageYOffset;
    const innerHeight = window.innerHeight;
    const limit = pageYOffset + innerHeight;
    if ((pageYOffset + innerHeight) < (rightPanelHeight + rightPanelTop) && pageYOffset >= rightPanelTop) {
      // stay same
    } else if ((pageYOffset + innerHeight) >= (rightPanelTop + rightPanelHeight)) {
      rightPanel.style.top = (limit - rightPanelHeight - 50) + 'px';
    } else if (pageYOffset < rightPanelTop) {
      rightPanel.style.top = (pageYOffset) + 'px';
    }
  }

  setRef = (node) => {
    this.node = node;
  }

  renderView = (link, index) => {
    const columnIndex = getIndex(this.context.router.history.location.pathname);
    const title = this.props.intl.formatMessage({ id: link.props['data-preview-title-id'] });
    const icon = link.props['data-preview-icon'];

    const view = (index === columnIndex) ?
      React.cloneElement(this.props.children) :
      <ColumnLoading title={title} icon={icon} />;

    return (
      <div className='columns-area columns-area--mobile' key={index}>
        {view}
      </div>
    );
  }

  renderLoading = columnId => () => {
    return columnId === 'COMPOSE' ? <DrawerLoading /> : <ColumnLoading />;
  }

  renderError = (props) => {
    return <BundleColumnError {...props} />;
  }

  handleShowCompose = () => {
    this.props.onShowCompose(true);
  }

  render () {
    const { columns, children, singleColumn, isModalOpen, account, showFilterBar } = this.props;
    const { shouldAnimate } = this.state;

    const columnIndex = getIndex(this.context.router.history.location.pathname);
    const path = this.context.router.history.location.pathname;
    const newsCategories = ['/timelines/public', '/timelines/trend', '/timelines/news'];
    const tabsBarHideCategories = [
      '/timelines/public', '/timelines/trend', '/timelines/news', '/notifications', '/settings/relationships', '/directory',
    ];
    const filterBarContainer = (showFilterBar && path.includes('/notifications')) ? (<NotificationFilterBarContainer />) : null;

    if (singleColumn) {
      const floatingActionButton = shouldHideFAB(this.context.router.history.location.pathname) ? null :  <div key='floating-new-post-button' className='floating-new-post-button z2' onClick={this.handleShowCompose} role='button' tabIndex={0} ><Icon id='new-post-white' size={20} /></div>;

      const content = columnIndex !== -1 ? (
        <ReactSwipeableViews key='content' hysteresis={0.2} threshold={15} index={columnIndex} onChangeIndex={this.handleSwipe} onTransitionEnd={this.handleAnimationEnd} animateTransitions={shouldAnimate} springConfig={{ duration: '400ms', delay: '0s', easeFunction: 'ease' }} disabled={disableSwiping}>
          {links.map(this.renderView)}
        </ReactSwipeableViews>
      ) : (
        <div key='content' className='columns-area columns-area--mobile'>{children}</div>
      );

      return (
        <div className='columns-area__panels'>
          <div className='columns-area__panels__pane columns-area__panels__pane--start columns-area__panels__pane--navigational'>
            <div className='columns-area__panels__pane__inner d-flex flex-column'>
              <div className='flex-fill'>
                <div className='relative-fill'>
                  <div className=''>
                    { account && (<NavigationPanel account={account} onShowCompose={this.handleShowCompose} />)}
                    { !account && (<UnsignedNavPanel />)}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className={classNames('columns-area__panels__main', { 'settings': path.includes('/settings/'), 'messages-panel': path.includes('/messages') })}>
            <div className='flex-fill'>
              <div className='relative-fill'>
                <div className='d-flex flex-column'>
                  {path.includes('/home') && <ComposeFormContainer singleColumn className='mb5 mobile-hide' />}
                  {(newsCategories.includes(path)) && <NewsNavigation />}
                  {(path === '/directory') && <DirectoryTab />}

                  {<TabsBar key='tabs' className={classNames({ 'd-none': tabsBarHideCategories.includes(path) || path.includes('/messages') })} />}
                  {filterBarContainer}
                  <div className='flex-fill post-rails rounded-bottom'>
                    {content}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {path !== '/settings/relationships' && !path.includes('/messages') && (
            <div className='columns-area__panels__pane columns-area__panels__pane--compositional relative-fill right-panel'>
              <div className='columns-area__panels__pane__inner'>
                <ComposePanel />
              </div>
            </div>
          )}

          { me && !path.includes('/messages') && floatingActionButton }
          { me && !path.includes('/messages') && <StickMessagePanel /> }
        </div>
      );
    }

    return (
      <div className={`columns-area ${isModalOpen ? 'unscrollable' : ''}`} ref={this.setRef}>
        {columns.map(column => {
          const params = column.get('params', null) === null ? null : column.get('params').toJS();
          const other = params && params.other ? params.other : {};

          return (
            <BundleContainer key={column.get('uuid')} fetchComponent={componentMap[column.get('id')]} loading={this.renderLoading(column.get('id'))} error={this.renderError}>
              {SpecificComponent => <SpecificComponent columnId={column.get('uuid')} params={params} multiColumn {...other} />}
            </BundleContainer>
          );
        })}

        {React.Children.map(children, child => React.cloneElement(child, { multiColumn: true }))}
      </div>
    );
  }

}
