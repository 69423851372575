import React from 'react';
import PropTypes from 'prop-types';
import PopoverLayout from './popover_layout';
import ColumnIndicator from '../../features/messages/other_components/column_indicator';

class LoadingPopover extends React.PureComponent {

  static propTypes = {
    isXS: PropTypes.bool,
    onClose: PropTypes.func,
  }

  render() {
    const { isXS } = this.props;

    return (
      <PopoverLayout
        width={250}
        isXS={isXS}
        onClose={this.props.onClose}
      >
        <div className={'d px15px py15px mt15px mb15px'}>
          <div className={'d px15px py15px mt15px mb15px align-items-center justify-content-center'}>
            <ColumnIndicator type='loading' />
          </div>
        </div>
      </PopoverLayout>
    );
  }

}

LoadingPopover.defaultProps = {
  isXS: PropTypes.bool,
  onClose: PropTypes.func,
};

export default LoadingPopover;