import { connect } from 'react-redux';
import Badge from 'brighteon/components/badge';

const mapStateToProps = (state) => ({
  count: state.getIn(['chats', 'chatsUnreadCount']),
  id: 'messages',
});

const ChatUnreadCounterBadge = connect(mapStateToProps)(Badge);

export default connect(mapStateToProps)(ChatUnreadCounterBadge);