import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Icon from 'brighteon/components/icon';
import { me } from '../../../initial_state';
import classNames from 'classnames';


class NewsNavigation extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  handleClick (timelineCategory, e) {
    e.preventDefault();
    if (timelineCategory === 'live_stream') {
      this.context.router.history.push('/timelines/public');
    } else if(timelineCategory === 'trending') {
      this.context.router.history.push('/timelines/trend');
    } else if(timelineCategory === 'news') {
      this.context.router.history.push('/timelines/news');
    }
  }

  handleLiveStream = (e) => {
    this.handleClick('live_stream', e);
  }

  handleTrending = (e) => {
    this.handleClick('trending', e);
  }

  handleNews = (e) => {
    this.handleClick('news', e);
  }

  render () {
    const path = this.context.router.history.location.pathname;

    return (
      <div className='sub-tab-container d-flex mb3'>
        { me && (
          <>
            <NavLink to='/timelines/home' activeClassName='active' >
              <div className='notification__nav-back'>
                <Icon id='arrow-left' size={30} />
              </div>
            </NavLink>
            <div className={classNames('sub-tab', { 'active': path === '/timelines/public' })} >
              <div onClick={this.handleLiveStream} className='cursor-pointer' role='button' tabIndex={0} >
                <FormattedMessage id='timelines.categories.live_stream' defaultMessage='Live Stream' />
              </div>
            </div>
          </>
        )
        }

        <div className={classNames('sub-tab', { 'active': path === '/timelines/trend' })} >
          <div onClick={this.handleTrending} role='button' tabIndex={0} className='cursor-pointer' >
            <FormattedMessage id='timelines.categories.trending' defaultMessage='Trending' />
          </div>
        </div>
        <div className={classNames('sub-tab', { 'active': path === '/timelines/news' })} >
          <div onClick={this.handleNews} role='button' tabIndex={0} className='cursor-pointer' >
            <FormattedMessage id='timelines.categories.news' defaultMessage='News' />
          </div>
        </div>
      </div>
    );
  }

}

export default withRouter(NewsNavigation);