import React from 'react';


export default class WelcomeContainer extends React.PureComponent {

  render() {
    return (
      <div className='widget latest-news'>
        <span className='title'>
          Welcome to Brighteon.Social
        </span>
        <div className='content'>
          <div className='text'>
            The tyranny of Big Tech is now on full display in America as Twitter has unleashed an army of so-called "fact-checkers" (i.e. left-wing propagandists) to place fact-check labels on the tweets of President Trump. This is why we built Brighteon.com and Brighteon.Social - so that many thousands of people can participate in news gathering and reporting.
          </div>
          <div className='actions'>
            <a href='/auth/sign_up' className='demo-button demo-button-a'>Sign Up</a>
            <a href='/auth/sign_in' className='demo-button demo-button-b'>Sign In</a>
            <a href='/about/more' target='_blank'>Learn More</a>
          </div>
        </div>
      </div>
    );
  }

}

