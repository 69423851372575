import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import Icon from 'brighteon/components/icon';
import classNames from 'classnames';

const tooltips = defineMessages({
  mentions: { id: 'notifications.filter.mentions', defaultMessage: 'Mentions' },
  replies: { id: 'notifications.filter.replies', defaultMessage: 'Replies' },
  favourites: { id: 'notifications.filter.favourites', defaultMessage: 'Favourites' },
  boosts: { id: 'notifications.filter.boosts', defaultMessage: 'Boosts' },
  polls: { id: 'notifications.filter.polls', defaultMessage: 'Poll results' },
  follows: { id: 'notifications.filter.follows', defaultMessage: 'Follows' },
  statuses: { id: 'notifications.filter.statuses', defaultMessage: 'Updates from people you follow' },
  messages: { id: 'notifications.filter.messages', defaultMessage: 'Messages' },
});

export default @injectIntl
class FilterBar extends React.PureComponent {

  static propTypes = {
    selectFilter: PropTypes.func.isRequired,
    selectedFilter: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
  };

  onClick (notificationType) {
    return () => this.props.selectFilter(notificationType);
  }

  render () {
    const { selectedFilter, intl } = this.props;
    const renderedElement = (
      <div className='notification__filter-bar mb3 overflow-x-scroll'>
        <NavLink to='/timelines/home' >
          <div className='notification__nav-back'>
            <Icon id='arrow-left' size={30} />
          </div>
        </NavLink>
        <div className={classNames('sub-tab', { 'active' : selectedFilter === 'all' })} >
          <div to='/notifications' onClick={this.onClick('all')} className='decoration-none cursor-pointer' role='button' tabIndex={0}>
            <FormattedMessage
              id='notifications.filter.all'
              defaultMessage='All'
            />
          </div>
        </div>
        <div
          className={classNames('sub-tab', { 'active' : selectedFilter === 'mention' })}
          title={intl.formatMessage(tooltips.mentions)}
        >
          <div to='/notifications' onClick={this.onClick('mention')} className='decoration-none cursor-pointer' role='button' tabIndex={0}>
            <FormattedMessage
              id='notifications.filter.mentions'
              defaultMessage='Mentions'
            />
          </div>
        </div>
        <div className={classNames('sub-tab', { 'active' : selectedFilter === 'reply' })} title={intl.formatMessage(tooltips.mentions)}>
          <div to='/notifications' onClick={this.onClick('reply')} className='decoration-none cursor-pointer' role='button' tabIndex={0}>
            <FormattedMessage
              id='notifications.filter.replies'
              defaultMessage='Replies'
            />
          </div>
        </div>
        <div className={classNames('sub-tab', { 'active' : selectedFilter === 'favourite' })} title={intl.formatMessage(tooltips.favourites)}>
          <div to='/notifications' onClick={this.onClick('favourite')} className='decoration-none cursor-pointer' role='button' tabIndex={0}>
            <FormattedMessage
              id='notifications.filter.favorites'
              defaultMessage='Favorites'
            />
          </div>
        </div>
        <div className={classNames('sub-tab', { 'active' : selectedFilter === 'reblog' })} title={intl.formatMessage(tooltips.boosts)}>
          <div to='/notifications' onClick={this.onClick('reblog')} className='decoration-none cursor-pointer' role='button' tabIndex={0}>
            <FormattedMessage
              id='notifications.filter.reposts'
              defaultMessage='Reposts'
            />
          </div>
        </div>
        <div className={classNames('sub-tab', { 'active' : selectedFilter === 'follow' })} title={intl.formatMessage(tooltips.follows)}>
          <div to='/notifications' onClick={this.onClick('follow')} className='decoration-none cursor-pointer' role='button' tabIndex={0}>
            <FormattedMessage
              id='notifications.filter.follows'
              defaultMessage='Follows'
            />
          </div>
        </div>
        <div className={classNames('sub-tab', { 'active' : selectedFilter === 'poll' })} title={intl.formatMessage(tooltips.polls)}>
          <div to='/notifications' onClick={this.onClick('poll')} className='decoration-none cursor-pointer' role='button' tabIndex={0}>
            <FormattedMessage
              id='notifications.filter.poll'
              defaultMessage='Polls'
            />
          </div>
        </div>
      </div>
    );
    return renderedElement;
  }

}
