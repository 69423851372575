import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePureComponent from 'react-immutable-pure-component';
import IconButton from '../../../../brighteon/components/icon_button';
import DisplayName from '../../messages/other_components/display_name';
import DisplayNameGroup from '../../messages/other_components/display_name_group';
import Avatar from '../../messages/other_components/avatar';
import AvatarGroup from '../../messages/other_components/avatar_group';
import classNames from 'classnames';
import { makeGetChatConversation } from '../../../selectors';
import StickMessageScrollingList from './stick_message_scrolling_list';
import StickMessageComposeFormContainer from '../containers/stick_message_compose_form_container';
import { closeStickMessage } from '../../../actions/chats';
import { openPopover } from '../../../actions/popover';
import {
  POPOVER_CHAT_CONVERSATION_OPTIONS,
} from '../../../constants';

class StickMessageTab extends ImmutablePureComponent {

  static propTypes = {
    chatConversationId: PropTypes.string.isRequired,
    chatConversation: PropTypes.object,
    onCloseStickMessage: PropTypes.func.isRequired,
  }

  state = {
    expanded: PropTypes.bool,
  }

  handleClick = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  closeStickMessage = () => {
    this.props.onCloseStickMessage(this.props.chatConversationId);
  }

  handleOnOpenChatConversationOptionsPopover = (e) => {
    this.props.onOpenChatConversationOptionsPopover({
      isHidden: false,
      isChatConversationRequest: false,
      chatConversationId: this.props.chatConversationId,
      targetRef: this.optionsBtnRef,
    });

    e.stopPropagation();
  }

  handleResizeTab = (e) => {
    window.location.href = `/web/messages/${this.props.chatConversationId}`;
    e.stopPropagation();
  }

  setOptionsBtnRef = (c) => {
    this.optionsBtnRef = c;
  }

  render () {
    const { expanded } = this.state;
    const { chatConversation, chatConversationId } = this.props;
    // const arrowId = expanded ? 'arrow-down' : 'arrow-up';
    const otherAccounts = chatConversation.get('other_accounts');
    return (
      <div className={classNames('border-medium1-1 bg-white w-340px mr10px', 'max-h-500px overflow-hidden d-flex flex-column box-shadow-emoticon')}>
        <div className={'border-bottom-medium1-1 p10px d-flex justify-content-space-between cursor-pointer'} onClick={this.handleClick} tabIndex='0' role='button' >
          {
            !!otherAccounts && otherAccounts.size === 1 &&
            <React.Fragment>
              <a href={otherAccounts.get(0).get('url')} className='d-flex align-items-center decoration-none max-w-100pc-86px overflow-hidden' target='_blank'>
                <Avatar account={otherAccounts.get(0)} size={30} />
                <div className={'d pl10px overflow-hidden'}>
                  <DisplayName account={otherAccounts.get(0)} />
                </div>
              </a>
            </React.Fragment>
          }
          {
            !!otherAccounts && otherAccounts.size > 1 &&
            <React.Fragment>
              <div className='d-flex max-w-100pc-86px overflow-hidden'>
                <AvatarGroup accounts={otherAccounts} size={30} maxVisible={3} showText />
                <DisplayNameGroup accounts={otherAccounts} />
              </div>
            </React.Fragment>
          }
          <div className='d-flex'>
            <IconButton
              title='Options'
              icon='ellipsis-h-big'
              size={20}
              onClick={this.handleOnOpenChatConversationOptionsPopover}
              buttonRef={this.setOptionsBtnRef}
              circle
              className='ml-auto'
            />
            <IconButton
              title='Options'
              icon='resize-small'
              size={20}
              onClick={this.handleResizeTab}
              circle
              className='ml-auto'
            />
            <IconButton
              title='Options'
              icon='clear-big'
              size={20}
              onClick={this.closeStickMessage}
              circle
              className='cursor-pointer'
            />
          </div>
        </div>
        {
          expanded && (
            <>
              <div className='min-h-150px pos-relative h-100 overflow-y-scroll'>
                <StickMessageScrollingList chatConversationId={chatConversationId} />
              </div>
              <StickMessageComposeFormContainer conversationId={chatConversationId} />
            </>
          )
        }
      </div>
    );
  }

}

const mapStateToProps = (state, { chatConversationId }) => ({
  chatConversation: makeGetChatConversation()(state, { id: chatConversationId }),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseStickMessage(chatId) {
    dispatch(closeStickMessage(chatId));
  },
  onOpenChatConversationOptionsPopover(options) {
    dispatch(openPopover(POPOVER_CHAT_CONVERSATION_OPTIONS, {
      ...options,
      position: 'left-start',
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StickMessageTab);
