import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ImmutablePureComponent from 'react-immutable-pure-component';
import StickMessageListContainer from './containers/stick_message_list_container';
import StickMessageTab from './components/stick_message_tab';
import openStickMessage from '../../actions/chats';

class StickMessagePanel extends ImmutablePureComponent {

  state = {
    expanded: true,
  };

  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { stickedChatIds } = this.props;
    const classname = classNames('pos-fixed bottom-0 right-0 h-0 d-flex align-items-end max-h-100vh-76px d-none-mobile');
    return (
      <div className={classname}>
        {
          stickedChatIds.map((chatId, i) => (
            <StickMessageTab chatConversationId={chatId} key={i} />
          ))
        }
        <StickMessageListContainer onExpand={this.handleExpand} expanded={this.state.expanded} />
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  stickedChatIds: state.getIn(['chats', 'stickedChatConversationIds'], null),
});

const mapDispatchToProps = (dispatch) => ({
  onOpenStickMessage: (chatConversationId) => {
    dispatch(openStickMessage(chatConversationId));
  },
});

StickMessagePanel.propTypes = {
  stickedChatIds: ImmutablePropTypes.list,
};

export default connect(mapStateToProps, mapDispatchToProps)(StickMessagePanel);
