import React from 'react';
import ImmutablePureComponent from 'react-immutable-pure-component';
import ReactSwipeableViews from 'react-swipeable-views';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import IconButton from '../../../../brighteon/components/icon_button';
import { defineMessages, injectIntl } from 'react-intl';
import { disableSwiping } from 'brighteon/initial_state';
import { me } from 'brighteon/initial_state';
import PromotedStatusContainer from '../../../../brighteon/containers/promoted_status_container';

const messages = defineMessages({
  close: { id: 'lightbox.close', defaultMessage: 'Close' },
  previous: { id: 'lightbox.previous', defaultMessage: 'Previous' },
  next: { id: 'lightbox.next', defaultMessage: 'Next' },
});

class Announcement extends ImmutablePureComponent {

  static propTypes = {
    announcement: ImmutablePropTypes.map.isRequired,
    emojiMap: ImmutablePropTypes.map.isRequired,
    addReaction: PropTypes.func.isRequired,
    removeReaction: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    dismissAnnouncement: PropTypes.func.isRequired,
  };

  state = {
    unread: !this.props.announcement.get('read'),
  };

  componentDidUpdate() {
    const { selected, announcement } = this.props;
    if (!selected && this.state.unread !== !announcement.get('read')) {
      this.setState({ unread: !announcement.get('read') });
    }
  }

  markAnnouncementAsRead = () => {
    const { dismissAnnouncement, announcement } = this.props;
    if (!announcement.get('read')) dismissAnnouncement(announcement.get('id'));
  }

  render() {
    const { announcement } = this.props;
    const status = announcement.getIn(['statuses', 0]);
    const statusId = status.get('id');

    return (
      <div className='announcements__item'>
        {status &&
          <PromotedStatusContainer
            key={`f-${statusId}`}
            id={statusId}
            showThread
            contextType='promoted'
          />
        }
      </div>
    );
  }

}

export default @injectIntl
class Announcements extends ImmutablePureComponent {

  constructor(props) {
    super(props);
    this.swipeableViewsRef = React.createRef();
  }

  static propTypes = {
    announcements: ImmutablePropTypes.list,
    emojiMap: ImmutablePropTypes.map.isRequired,
    dismissAnnouncement: PropTypes.func.isRequired,
    addReaction: PropTypes.func.isRequired,
    removeReaction: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    index: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.announcements.size > 0 && state.index >= props.announcements.size) {
      return { index: props.announcements.size - 1 };
    } else {
      return null;
    }
  }

  componentDidMount() {
    this._markAnnouncementAsRead();
    setTimeout(() => {
      this.swipeableViewsRef.current.getSwipeableViewsContext().slideUpdateHeight();
    }, 0);
  }

  componentDidUpdate() {
    this._markAnnouncementAsRead();
  }

  _markAnnouncementAsRead() {
    if (!me) return;

    const { dismissAnnouncement, announcements } = this.props;
    const { index } = this.state;
    const announcement = announcements.get(announcements.size - 1 - index);
    if (!announcement.get('read')) dismissAnnouncement(announcement.get('id'));
  }

  handleChangeIndex = index => {
    this.setState({ index: index % this.props.announcements.size });
  }

  handleNextClick = () => {
    this.setState({ index: (this.state.index + 1) % this.props.announcements.size });
  }

  handlePrevClick = () => {
    this.setState({ index: (this.props.announcements.size + this.state.index - 1) % this.props.announcements.size });
  }

  render() {
    const { announcements, intl, dismissAnnouncement } = this.props;
    const { index } = this.state;

    if (announcements.isEmpty()) {
      return null;
    }

    return (
      <div className='announcements'>
        <div className='announcements__container'>
          <div className='header'>
            <span className='title'>Sponsored Post</span>
            {announcements.size > 1 && (
              <div className='announcements__pagination'>
                <IconButton disabled={announcements.size === 1} title={intl.formatMessage(messages.previous)} icon='arrow-left' onClick={this.handlePrevClick} size={16} />
                <div><span>{index + 1} / {announcements.size}</span></div>
                <IconButton disabled={announcements.size === 1} title={intl.formatMessage(messages.next)} icon='arrow-right' onClick={this.handleNextClick} size={16} />
              </div>
            )}
          </div>


          <div>
            <ReactSwipeableViews index={index} onChangeIndex={this.handleChangeIndex} animateHeight ref={this.swipeableViewsRef}>
              {announcements.map((announcement, idx) => (
                <Announcement
                  key={announcement.get('id')}
                  announcement={announcement}
                  dismissAnnouncement={dismissAnnouncement}
                  emojiMap={this.props.emojiMap}
                  addReaction={this.props.addReaction}
                  removeReaction={this.props.removeReaction}
                  intl={intl}
                  selected={index === idx}
                  disabled={disableSwiping}
                />
              )).reverse()}
            </ReactSwipeableViews>
          </div>
        </div>
      </div>
    );
  }

}
