import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import noop from 'lodash.noop';
import ImmutablePureComponent from 'react-immutable-pure-component';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List as ImmutableList } from 'immutable';
import {
  fetchChatConversations,
  expandChatConversations,
} from '../../../actions/chat_conversations';
import AccountPlaceholder from '../../messages/other_components/placeholder/account_placeholder';
import StickMessageListItem from './stick_message_list_item';
import ChatConversationsListHeader from '../../messages/components/chat_conversations_list_header';
import ScrollableList from '../../messages/other_components/scrollable_list';

class StickMessageList extends ImmutablePureComponent {

  componentDidMount() {
    this.props.onFetchChatConversations();
  }

  handleLoadMore = debounce(() => {
    this.props.onExpandChatConversations();
  }, 300, { leading: true })

  render() {
    const {
      hasMore,
      isLoading,
      chatConversationIds,
      pinnedChatConversationIds,
      isSearching,
    } = this.props;

    const showPinned = !isSearching && pinnedChatConversationIds.size > 0;

    return (
      <div className={'d w-100 max-h-100pc-37px overflow-y-auto'}>
        { showPinned && <ChatConversationsListHeader title='PINNED CHATS' /> }
        { showPinned &&
          <ScrollableList
            scrollKey='chat-conversations-top'
            onLoadMore={this.handleLoadMore}
            onScrollToTop={noop}
          >
            {
              pinnedChatConversationIds.map((chatConversationId, i) => (
                <StickMessageListItem
                  key={`chat-conversation-pinned-${i}`}
                  chatConversationId={chatConversationId}
                  source='approved'
                />
              ))
            }
          </ScrollableList>
        }
        <ScrollableList
          scrollKey='chat-conversations-all'
          onLoadMore={this.handleLoadMore}
          hasMore={hasMore}
          isLoading={isLoading}
          showLoading={isLoading}
          placeholderComponent={AccountPlaceholder}
          onScrollToTop={noop}
          placeholderCount={3}
          emptyMessage='Empty'
        >
          {
            chatConversationIds.map((chatConversationId, i) => (
              <StickMessageListItem
                key={`chat-conversation-${i}`}
                chatConversationId={chatConversationId}
                source='approved'
              />
            ))
          }
        </ScrollableList>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  let chatConversationIds, pinnedChatConversationIds = ImmutableList();
  const chatSearchValue = state.getIn(['chats', 'searchValue'], '');
  if (!!chatSearchValue && chatSearchValue.length > 0) {
    chatConversationIds = state.getIn(['chat_conversation_lists', 'approved_search', 'items']);
  } else {
    pinnedChatConversationIds = state.getIn(['chat_conversation_lists', 'approved_pinned', 'items']);
    chatConversationIds = state.getIn(['chat_conversation_lists', 'approved', 'items']);
  }

  return {
    pinnedChatConversationIds,
    chatConversationIds,
    isSearching: !!chatSearchValue,
    hasMore: !!state.getIn(['chat_conversation_lists', 'approved', 'next']),
    isLoading: state.getIn(['chat_conversation_lists', 'approved', 'isLoading']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchChatConversations() {
    dispatch(fetchChatConversations());
  },
  onExpandChatConversations() {
    dispatch(expandChatConversations());
  },
});

StickMessageList.propTypes = {
  chatConversationIds: ImmutablePropTypes.list,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFetchChatConversations: PropTypes.func.isRequired,
  onExpandChatConversations: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StickMessageList);