import React from 'react';

class BrighteonTvNewsContainer extends React.PureComponent {

  render() {
    return (
      <div className='widget'>
        <span className='title'>
          New on Brighteon.TV
        </span>
        <div className='content'>
          <div id='BrighteonTVEpisodes' />
          <div className='actions'>
            <a href='https://www.brighteon.tv' target='_blank' className='demo-button demo-button-a demo-button--block'>Watch Brighteon.TV</a>
            <a href='https://www.brighteon.com/channels/brighteontv' target='_blank'>Previous Episodes</a>
          </div>
        </div>
      </div>
    );
  }

}

export default BrighteonTvNewsContainer;
