import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Icon from '../../../../brighteon/components/icon';
import { onChangeSearch } from '../../../actions/chats';
import { searchApprovedChatConversations } from '../../../actions/chat_conversations';

class StickMessageSearch extends React.PureComponent {

  handleOnChange = (e) => {
    this.props.onChange(e.target.value);
  }

  render() {
    const { value } = this.props;
    return (
      <div className={'chat-sidebar-top pos-relative d-flex border-bottom-medium1-1'}>
        <Icon id='search-gray' size={24} className={classNames('pos-absolute', 'left-10px top-7px')} />
        <input
          className='search-input h-34px radius-rounded-4px w-100 pl40px border-none'
          placeholder='Search For Contacts'
          value={value}
          onChange={this.handleOnChange}
        />
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  value: state.getIn(['chats', 'searchValue'], ''),
});

const mapDispatchToProps = (dispatch) => ({
  onChange(value) {
    dispatch(onChangeSearch(value));
    dispatch(searchApprovedChatConversations(value));
  },
});

StickMessageSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StickMessageSearch);
