import React from 'react';
import PropTypes from 'prop-types';

const formatNumber = num => num > 40 ? '40+' : num;

const Badge = ({ count, issueBadge }) => (
  <i className='icon-with-badge left-neg-15px'>
    {count > 0 && <i className='icon-with-badge__badge bg-red1'>{formatNumber(count)}</i>}
    {issueBadge && <i className='icon-with-badge__issue-badge' />}
  </i>
);

Badge.propTypes = {
  id: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  issueBadge: PropTypes.bool,
  className: PropTypes.string,
};

export default Badge;
