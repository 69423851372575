import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import Icon from '../../../../brighteon/components/icon';

const iconStyle = {
  size: 24,
};

const navLinkClass = 'column-link column-link--transparent d-flex';

const MenuTextContainer = (props) => {
  return (
    <div className='ml2 pt1'>
      <FormattedMessage {...props} />
    </div>
  );
};

class UnsignedNavPanel extends ImmutablePureComponent {

  render() {
    return (
      <div className='navigation-panel-left'>
        <NavLink className={navLinkClass} to='/timelines/trend' data-preview-title-id='column.home' data-preview-icon='home' >
          <Icon className='column-link__icon' id='home' size={iconStyle.size} />
          <MenuTextContainer id='tabs_bar.home' defaultMessage='Home' />
        </NavLink>

        <NavLink className={navLinkClass} to='/timelines/news' data-preview-title-id='column.trend'>
          <Icon className='column-link__icon' id='news' size={iconStyle.size} />
          <MenuTextContainer id='tabs_bar.news' defaultMessage='News' />
        </NavLink>

        <br />

        <a className={navLinkClass} href='http://www.brighteonstore.com' target='_blank' rel='noopener noreferrer'>
          <Icon className='column-link__icon left-menu-icon' id='shop' size={24} />
          <MenuTextContainer id='navigation_bar.shop' defaultMessage='Shop' />
        </a>

        <a className={navLinkClass} href='https://donation.brighteon.com' target='_blank' rel='noopener noreferrer'>
          <Icon className='column-link__icon left-menu-icon' id='donate' size={24} />
          <MenuTextContainer id='navigation_bar.donate' defaultMessage='Donate' />
        </a>

        <a className={navLinkClass} href='/help' target='_blank' rel='noopener noreferrer'>
          <Icon className='column-link__icon left-menu-icon' id='help' size={24} />
          <MenuTextContainer id='navigation_bar.help' defaultMessage='Help' />
        </a>
      </div>
    );
  }

}

export default withRouter(UnsignedNavPanel);
