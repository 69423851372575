import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  fetchArticles,
} from '../../../../brighteon/actions/uncensored_news_feed';
import { FeedItem } from '../../uncensored_news_feed/components/feed_item';

const mapStateToProps = (state) => ({
  isLoading: state.getIn(['uncensored_news_feed', 'isLoading']),
  articles: state.getIn(['uncensored_news_feed', 'articles']),
  hasMore: state.getIn(['uncensored_news_feed', 'hasMore']),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchArticles: () => {
    dispatch(fetchArticles());
  },
});

export default @connect(mapStateToProps, mapDispatchToProps)
class LatestNewsContainer extends React.PureComponent {

  static propTypes = {
    onFetchArticles: PropTypes.func.isRequired,
    articles: ImmutablePropTypes.list,
  };

  componentDidMount() {
    this.props.onFetchArticles();
  }

  render() {
    const { articles } = this.props;

    const scrollableContent = articles.slice(0, 5).valueSeq().map((item) => (
      <FeedItem
        key={item.get('article')}
        headline={item.get('headline')}
        date={item.get('date')}
        link={item.get('link')}
        image={item.get('cover')}
        newsSource={item.get('News Source')}
      />
    ));

    return (
      <div className='widget latest-news'>
        <span className='title'>
          Latest News
        </span>
        <div className='content'>
          <div className='text'>
            {scrollableContent}
          </div>
          <div className='actions'>
            <a href='https://censored.news' target='_blank' className='demo-button demo-button-a demo-button--block'>More headlines</a>
          </div>
        </div>
      </div>
    );
  }

}

