import './public-path';
import loadPolyfills from '../brighteon/load_polyfills';
import { start } from '../brighteon/common';

start();

loadPolyfills().then(() => {
  require('../brighteon/main').default();
}).catch(e => {
  console.error(e);
});
